import React from 'react';
//import logo from './s1iwy.JPG';
import {
 // Flex,
  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';

function Home (){
	return (<>
	


	<div className='navBarBox' >    <span className="navBarTitle"><Link style={{ textDecoration: 'none', color: 'white' }} to="/">VGServers </Link></span>

<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/">  Home</Link>

<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/about">About</Link>

</div>
<div className='pageContent' >

	<br></br>
	<Heading level={2}>VGServers Help</Heading>
	<br></br>
	
</div>	

<div style={{ textAlign: 'left', paddingLeft: '10%' }}>
<span className='pageContentMed'>GENERAL USE</span>
<p style={{ paddingLeft: '2vmin' }}>
-To start the Cloud Server, use the START button on the VGServers Portal<br></br>
-Connect to the Cloud Server using RDP by clicking the RDP CONNECT button on the VGServers Portal<br></br>
-Buy more time via PayPal with the Add Time button<br></br>
-To shutdown the Cloud Server, use the STOP button on the VGServers Portal<br></br>
-The Cloud Server will backup each time it is stopped.
</p>
<br></br>
<span className='pageContentMed'>ADDITIONAL FEATURES</span>
<p style={{ paddingLeft: '2vmin' }}>
-Reset Cloud Server Software to Initial State: While Cloud Server is STOPPED, use the OPTION Button and then the RESET Button.
-Rollback Cloud Server to last Backup: While Cloud Server is RUNNING, use the OPTION Button and then the ROLLBACK Button.
-SMS Time Alerts: To enable and receive alerts when you are about to run out of time, use the OPTIONS Button and then the SMS ALERTS Button.
</p>


	</div>

	  </>)
		
	
	
}

export default Home;

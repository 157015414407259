import React from 'react';
//import logo from './s1iwy.JPG';
import {
  Flex,
  Heading,
  Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';

import background from "../b4.png";
import backgroundC from "./a12.png";



function Home (){


 if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};
 // var tmpgap = '0rem';

	return (<>
	

	<div className='navBarBox' >    <span className="navBarTitle"><Link style={{ textDecoration: 'none', color: 'white' }} to="/">VGServers </Link></span>

<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/">  Home</Link>

<Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/about">About</Link>

</div>


<div hidden = {true} className='divBox3'> <br></br></div>
<div className='pageContent'>

<div style={{ backgroundImage: `url(${background})`, backgroundRepeat:"no-repeat",backgroundPosition:"center top",backgroundSize:"cover" ,backgroundAttachment: 'fixed'  }}>
<br></br>
	<Heading level={2} color='white' marginLeft='2%' marginRight='2%' >Virtual Game Servers</Heading>
	
	
	<span className='pageContentMed2'>On-Demand Windows Game Servers</span>
	<br></br><br></br>
	<Flex
		direction="row"
		justifyContent="center"
		alignItems="center"
		textAlign="center"
		marginLeft='3vw'
		marginRight='3vw'
		
	>
	<span className='pageContentMed2'>BETA Phase - Request Access at <a  style={{ textDecoration: 'none', color: '#fcef42' }} href="mailto:support@vgservers.com">support@vgservers.com</a> </span>
	</Flex>
	<br></br>
</div>
	










<Flex
			direction="row"
			justifyContent="space-between"
			alignItems="strech"
			textAlign="center"
			fontSize='23px'		
			//backgroundColor='red'	
			gap = '5vw'
			//marginTop='15px'
			
		>








<div className = 'contentSide'  >
</div>





<div>




<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'		
			color='black'	
			marginTop='15px'
		>
		<span>AVAILABLE CONFIGURATIONS:</span>
		
		</Flex>
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'
		>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="left"
			textAlign="left"
			gap="calc(38px - 6vw)"
			marginLeft='2px'
			marginRight='2px'
			wrap='wrap'
			fontSize='16px'
		//	boxShadow='inset 0 0 0 1px grey'
			//paddingRight='6vw'
			//paddingLeft='6vw'
		//	paddingTop='4px'
	//		paddingBottom='4px'
			backgroundColor='dark grey'
			color='black'
		>
    <span><span style={{textDecoration: 'underline' }}>Cloud Server1</span> - 1 vCPU, 4GB RAM: Basic Game Server - for lower RAM needs, suitable for 1-10 player MC server.</span>
		<span><span style={{textDecoration: 'underline'}}>Cloud Server2</span> - 1 vCPU, 8GB RAM: Standard Game Server - single-thread mid-range server, suitable for 1-5 player ARK server.</span>
    <span><span style={{textDecoration: 'underline'}}>Cloud Server3</span> - 2 vCPU, 8GB RAM: Enhanced Game Server - dual-thread mid-range server, suitable for 6-10 player ARK server.</span>
    <span><span style={{textDecoration: 'underline'}}>Cloud Server4</span> - 4 vCPU, 16GB RAM: Performance Game Server - quad-thread high end dedicated server.</span>

		</Flex>
		</Flex>





    <Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'		
			color='black'	
			marginTop='15px'
		>
	<span>CLOUD SERVER FEATURES:</span>	
		
		</Flex>
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'
		>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="left"
			textAlign="left"
			gap="calc(38px - 6vw)"
			marginLeft='2x'
			marginRight='2px'
			wrap='wrap'
			fontSize='16px'
		//	boxShadow='inset 0 0 0 1px grey'
			//paddingRight='6vw'
			//paddingLeft='6vw'
	//		paddingTop='4px'
	//		paddingBottom='4px'
			backgroundColor='dark grey'
			color='black'
		>
				
	
        <span>1) <span style={{textDecoration: 'underline' }}>Fully Functional Windows Server with RDP Remote Access</span>: Login and use the server as if it were your own local desktop.</span>
<span>2) <span style={{textDecoration: 'underline' }}>DIY Software Setup & Install</span>: 100% control over the server OS, firewall and installed software.</span>
<span>3) <span style={{textDecoration: 'underline' }}>Built-in Hardware Support</span>: No technical skills required, the cloud server is maintained by VGServers and powered by AWS.</span>
<span>4) <span style={{textDecoration: 'underline' }}>Pay Only For What You Use</span>: Minutes are only consumed while the server is actively running.</span>
<span>4) <span style={{textDecoration: 'underline' }}>Automatic Server Backup</span>: The server is backed up each time it is shut down and can be rolled back to the previous save.</span>
<span>5) <span style={{textDecoration: 'underline' }}>Fast Software Reset</span>: Reset your server to its initial 'just installed' state with the click of a button.</span>

	
		</Flex>
		</Flex>









    <Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'		
			color='black'	
			marginTop='15px'
		>
	<span>CLOUD SERVER HARDWARE</span>		
		
		</Flex>
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'
		>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="left"
			textAlign="left"
			gap="calc(38px - 6vw)"
			marginLeft='2px'
			marginRight='2px'
			wrap='wrap'
			fontSize='16px'
			//boxShadow='inset 0 0 0 1px grey'
			//paddingRight='6vw'
			//paddingLeft='6vw'
	//		paddingTop='4px'
	//		paddingBottom='4px'
//			marginBottom='20px'
			backgroundColor='dark grey'
			color='black'
		>
				

        <span>CPU: Intel Xeon Platinum 8259CL @ 2.5GHz Base/ 3.1GHz Turbo and AMD EPYC 7R32 @ 2.8Ghz Base / 3.3Ghz Turbo</span>
<span>STORAGE: 350 IOPS SSD (Value setting, higher speeds available upon request for an additional $0.02/hr per 125 IOPS)</span>
<span>NOTE: Unlimited use CPU & Storage, full utilization allowed.  No CPU or Storage throttling employed.</span>
</Flex>
			
		</Flex>

		











<Flex 
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="3vw"
      margin="20px"
		>
	
		
			<Image
        src={backgroundC}
        style={{ width: '500px' }}
      />


	  
</Flex>


</div>










<div className = 'contentSide' >
</div>








</Flex>









</div>





	  </>
		
	
	)
}

export default Home;

import React from 'react';
//import logo from './s1iwy.JPG';
import {
 Flex,
  Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';
//import background from "../background3.jpeg";
import background from "../b4.png";
//import background2 from "../b30.png";





function Home (){

 // if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};
// var tmpgap = '0rem';


	//var tmpnum = Math.random();
	//if( tmpnum < 0.33) {var tmpbg = '1' 
//} else if ( tmpnum < 0.66 && tmpnum > 0.33) {
//	tmpbg='2'
//} else {
//	 tmpbg = '3'
//	};





	function goApp() {

		window.open("https://app.vgservers.com");
	
	
	};


	return (<>
	

	

	<div className='navBarBox' >  
	
	

	 <span className="navBarTitle"><Link style={{ textDecoration: 'none', color: 'white' }} to="/">VGServers </Link></span>

<Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/">  Home</Link>

<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/about">About</Link>

</div>


<div hidden = {true} className='divBox3'> <br></br></div>

<div className='pageContent'>

<div style={{ backgroundImage: `url(${background})`, backgroundRepeat:"no-repeat",backgroundPosition:"center top",backgroundSize:"cover" ,backgroundAttachment: 'fixed'  }}>


	<br></br>
	<Heading level={2} color='white' marginLeft='2%' marginRight='2%' >Virtual Game Servers</Heading>
	
	
	<span className='pageContentMed2'>On-Demand Windows Game Servers</span>
	<br></br><br></br>
	<Flex
		direction="row"
		justifyContent="center"
		alignItems="center"
		textAlign="center"
		marginLeft='3vw'
		marginRight='3vw'
		
	>
	<span className='pageContentMed2'>BETA Phase - Request Access at <a  style={{ textDecoration: 'none', color: '#fcef42' }} href="mailto:support@vgservers.com">support@vgservers.com</a> </span>
	</Flex>
	<br></br>
	</div>
	

	
	




	<Flex
			direction="row"
			justifyContent="space-between"
			alignItems="strech"
			textAlign="center"
			fontSize='23px'		
			//backgroundColor='red'	
			gap = '5vw'
			//marginTop='15px'
			
		>





<div className = 'contentSide' >



</div>






<div>




	
<button className = "App-link-button" style={{ marginTop: "25px" , paddingTop: "0px", paddingBottom: "6px",  fontWeight: "", display: "inline", fontSize: "38px", height: "auto", width: "auto" }} onClick={goApp}>Login Here</button>
	
	



<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'		
			color='black'	
			marginTop='15px'
		>
		<span>WINDOWS GAME SERVERS:</span>
		<br></br>
		</Flex>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='16px'
		>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="left"
			textAlign="center"
			gap="calc(38px - 6vw)"
			marginLeft='0px'
			marginRight='0px'
			wrap='wrap'
			fontSize='18px'
		//	boxShadow='inset 0 0 0 1px grey'
	//		paddingRight='6vw'
	//		paddingLeft='6vw'
			paddingTop='4px'
			//paddingBottom='4px'
			backgroundColor='dark grey'
			color='black'
		>
		<span>Cloud Server1 - (1x) 2.50GHz vCPU with 4GB RAM - $0.22/hr</span>
		<span>Cloud Server2 - (1x) 2.50GHz vCPU with 8GB RAM - $0.27/hr</span>
		<span>Cloud Server3 - (2x) 2.50GHz vCPU with 8GB RAM - $0.37/hr</span>
		<span>Cloud Server4 - (4x) 2.8GHz vCPU with 16GB RAM - $0.63/hr</span>
		
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='14px'
			//paddingBottom='4px'
		>
		<span>*Billed in 1 min increments while server is running</span>
		</Flex>

		
		</Flex>



		


		
		</Flex>




		



		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'		
			color='black'	
			marginTop='15px'
		>
	<span>STORAGE OPTIONS:</span>	
		<br></br>
		</Flex>
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'
		>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="left"
			textAlign="left"
			//gap="calc(35px - 6vw)"
			gap='1px'
			//marginLeft='20px'
			//marginRight='20px'
			wrap='wrap'
			fontSize='18px'
		//	boxShadow='inset 0 0 0 1px grey'
	//		paddingRight='6vw'
		//	paddingLeft='6vw'
		//	paddingTop='4px'
		//	paddingBottom='4px'
			backgroundColor='dark grey'
			color='black'
		>
				
		<span>100GB SSD	- $5.75/mo (+$0.00/hr)</span>
		<span>200GB	SSD - $11.25/mo (+$0.02/hr)</span>
		<span>300GB	SSD - $16.75/mo (+$0.04/hr)</span>
	
		</Flex>
		</Flex>



		




		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'		
			color='black'	
			marginTop='15px'
		>
	<span>SYSTEM OPTIONS:</span>		
		<br></br>
		</Flex>
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'
		>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="left"
			textAlign="left"
			//gap="calc(35px - 6vw)"
			gap='1px'
			//marginLeft='20px'
			//marginRight='20px'
			wrap='wrap'
			fontSize='18px'
			//boxShadow='inset 0 0 0 1px grey'
		//	paddingRight='6vw'
		//	paddingLeft='6vw'
	//		paddingTop='4px'
	//		paddingBottom='4px'
			marginBottom='20px'
			backgroundColor='dark grey'
			color='black'
		>
				
		<span>Reserved IP Address - $3.65/mo</span>
		<span>Datacenter1 - US West - Oregon</span>
		<span>Datacenter2 - US East - Virginia</span>


			
		</Flex>
		</Flex>















		</div>








		<div className = 'contentSide'  >



</div>







		</Flex>








		

	</div>

	  </>
		
	
	)
}

export default Home;
